import React, {Component} from 'react';
import {Link} from 'react-router-dom';
class Header extends Component {
  render(){
    const sticky = typeof this.props.sticky === 'undefined' ? false : this.props.sticky;
    const transparent = typeof this.props.transparent === 'undefined' ? false : this.props.transparent;
    const dark = typeof this.props.dark === 'undefined' ? false : this.props.dark;
    const aboutMe = typeof this.props.aboutMe === 'undefined' ? false : this.props.aboutMe;
    const back = typeof this.props.back === 'undefined' ? false : true;
    console.log(sticky);
    return(
      <section className={"section navbar-01 scrolling " + (sticky ? " sticky" : "") + (transparent ? " transparent" : "") + (dark ? " dark" : "")} data-sticky={sticky} id="top">
        <div className="container">
          <nav>
            <Link to="/" className="brand">
              {!back &&
                <div>
                  Doug Watro
                </div>
              }
              {back &&
                <div>
                  <i className="fas fa-chevron-left"></i> Back
                </div>
              }
            </Link>
            <a className="mobile"><i className="fas fa-bars"></i></a>
            <ul className="right">
              {!aboutMe &&
                <li>
                  <Link to="/aboutme">About Me</Link>
                </li>
              }
              {!aboutMe &&
                <li className="divider"/>
              }
              <li>
                <a className={"button-outline-small-thin-" + (dark ? "light":"dark")} href="https://docs.google.com/gview?url=https://DougWatro.com/resources/Resume.pdf&embedded=true">Resume</a>
              </li>
            </ul>
          </nav>
        </div>
      </section>
    )
  }
}

export default Header;
