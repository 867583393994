import React, {Component} from 'react';
import {backgroundImage} from '../../helpers/helpers';
import {Link} from 'react-router-dom';
import Footer from '../footer';
import Header from '../header';
import bg_02 from '../../sources/img/sound-mixer/bg_02.jpg';

import boxes from '../../sources/img/frontpage/boxes.jpg';
import indecision from '../../sources/img/frontpage/indecision.jpg';
import rox from '../../sources/img/frontpage/rox.jpg';
import mixer from '../../sources/img/frontpage/soundmixer.jpg';
import mixerText from '../../sources/img/frontpage/soundmixer.svg'
import web from '../../sources/img/frontpage/web_dev.jpg';

class FrontPage extends Component{
  render(){
    const BG_02 = backgroundImage(bg_02);
    return(
      <div className="frontpage">
        <Header transparent={true} dark={true}/>
        <section className="hero hero-60 section dark bg-sound-mixer-accent-dark">
          <i className="background darken opacity-3 top" style={BG_02}></i>
          <div className="container center-all">
            <div className="row">
              <div className="col-11 col-lg-6">
                <div className="v-center">
                  <div className="v-center-child hero-text">
                    <h1>HELLO!</h1>
                    <h2>Great To Have You Here!</h2>
                  </div>
                </div>
              </div>
              <div className="col-11 col-lg-6">
              <div className="v-center">
                <div className="v-center-child hero-text">
                  <h3>I'm Doug</h3>
                  <h4>A Programmer, Web Developer, Game Designer</h4>
                  <Link to="/sound_mixer" className="button-light-outline">Check Out My Current Project</Link>
                </div>
              </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section bg-none px-lg-4 px-0 p-xsmall">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <Link to="/sound_mixer">
                  <div className="frontpage-link sound-mixer d-flex">
                    <ul className="no-style">
                      <li>Javascript</li>
                      <li>React/Redux</li>
                      <br/>
                      <li>CSS/SASS</li>
                      <li>HTML/Bootstrap</li>
                    </ul>
                    <img src={mixerText} alt="Soundscape Builder" className="svg-title align-self-center"/>
                    <img src={mixer} alt="" className="bg"/>
                  </div>
                </Link>
              </div>
              <div className="col-12">
                <Link to="/boxes">
                  <div className="frontpage-link boxes">
                    <h5>An Experiment In Personality</h5>
                    <h1>Hoppin' Boxes</h1>
                    <ul className="no-style">
                      <li>C++</li>
                      <li>Unreal Engine 4</li>
                      <li>Maya</li>
                    </ul>
                    <img src={boxes} alt="" className="bg"/>
                  </div>
                </Link>
              </div>
              <div className="col-12">
                <a href="/rox">
                  <div className="frontpage-link dark">
                    <h5>A Multipurpose Template</h5>
                    <ul className="no-style">
                      <li>Javascript</li>
                      <li>CSS/SASS</li>
                      <br/>
                      <li>HTML/Bootstrap</li>
                      <li>Maya</li>
                    </ul>
                    <img src={rox} alt="" className="bg"/>
                  </div>
                </a>
              </div>
              <div className="col-12">
                <Link to="/indecision">
                  <div className="frontpage-link dark indecision">
                    <h5>An Android App</h5>
                    <h1>Indecision</h1>
                    <ul className="no-style">
                      <li>Android SDK</li>
                      <li>Java</li>
                    </ul>
                    <img src={indecision} alt="" className="bg"/>
                  </div>
                </Link>
              </div>
              <div className="col-12">
                <Link to="/webconcepts">
                  <div className="frontpage-link dark webdev">
                    <h5>Web Concept Work</h5>
                    <ul className="no-style">
                      <li>Javascript</li>
                      <li>CSS/SASS</li>
                      <br/>
                      <li>HTML/Bootstrap</li>
                    </ul>
                    <img src={web} alt="" className="bg"/>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <Footer/>
      </div>
    );
  }
}

export default FrontPage;
