import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import SoundMixer from './components/body/sound_mixer';
import Boxes from './components/body/boxes';
import Indecision from './components/body/indecision';
import FrontPage from './components/body/frontpage';
import WebConcepts from './components/body/webconcepts';
import AboutMe from './components/body/aboutme';
import ScrollToTop from './components/wrappers/ScrollToTop';


function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
          <Switch>
            <Route path="/sound_mixer" component={SoundMixer}/>
            <Route path="/boxes" component={Boxes}/>
            <Route path="/indecision" component={Indecision}/>
            <Route path="/webconcepts" component={WebConcepts}/>
            <Route path="/aboutme" component={AboutMe}/>
            <Route path="/" component={FrontPage}/>
          </Switch>
        </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
