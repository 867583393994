import React, {Component} from 'react';
import Footer from '../footer';
import Header from '../header';
import {backgroundImage} from "../../helpers/helpers";

import ideaguys from '../../sources/img/webdev/ideaguys.jpg';
import bundt from '../../sources/img/webdev/bundt.jpg';
import lyrathol from '../../sources/img/webdev/lyrathol.jpg';
import music from '../../sources/img/webdev/music.jpg';

import bg_02 from '../../sources/img/sound-mixer/bg_02.jpg';

class WebConcepts extends Component {
  render(){
    const BG_02 = backgroundImage(bg_02);
    return(
      <div  className="webconcepts">
        <Header dark transparent back/>
        <section className="section hero-40 dark bg-dark-1">
          <i className="background hard-light opacity-1 top" style={BG_02}></i>
          <div className="container">
            <div className="row">
              <div className="col-12 text-smaller">
                <h1>Web Concepts</h1>
                <p>A small collection of concept designs for hypothetical websites and companies.</p>
                <p>Each of these are small 1 day projects experimenting with a variety of designs and styles.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="section p-small">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-lg-8 order-2 order-lg-1">
                <div className="site-example">
                  <a className="hover-state" href="/webdesign/ideaguys">
                    <i className="fas fa-external-link-alt"></i>
                  </a>
                  <div className="img-container">
                    <img src={ideaguys} alt="Example"/>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 order-1 order-lg-2 mt-lg-0">
                <div className="desc">
                  <h1>Idea Guys</h1>
                  <ul className="no-style inline tags">
                    <li>Bootstrap</li>
                    <li>jQuery</li>
                    <li>Sass</li>
                    <li>Wordpress</li>
                  </ul>
                  <a className="button-outline-dark-thin-small ml-0 mb-3 mt-2" href="/webdesign/ideaguys">View</a>
                  <p>A simple podcast website with working Soundcloud integration and collapsible list. An emphasis on simplicity as take, recognizing that most users who visit a podcast site are there to listen to a podcast.</p>
                  <ul className="no-style inline tags">
                    <li>Podcast</li>
                    <li>Streaming</li>
                    <li>Entertainment</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section p-small">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-lg-8 order-2 order-lg-1">
                <div className="site-example">
                  <a className="hover-state" href="/webdesign/bundt">
                    <i className="fas fa-external-link-alt"></i>
                  </a>
                  <div className="img-container">
                    <img src={bundt} alt="Example"/>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 order-1 order-lg-2 mt-lg-0">
                <div className="desc">
                  <h1>Bundt</h1>
                  <ul className="no-style inline tags">
                    <li>Bootstrap</li>
                    <li>jQuery</li>
                    <li>Sass</li>
                  </ul>
                  <a className="button-outline-dark-thin-small ml-0 mb-3 mt-2" href="/webdesign/bundt">View</a>
                  <p>A startup/web-business site. Sterile, a bit playful, a bit quirky. One page structure with an easy to navigate design.</p>
                  <ul className="no-style inline tags">
                    <li>Startup</li>
                    <li>Company</li>
                    <li>Quirky</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section p-small">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-lg-8 order-2 order-lg-1">
                <div className="site-example">
                  <a className="hover-state" href="/webdesign/lyrathol">
                    <i className="fas fa-external-link-alt"></i>
                  </a>
                  <div className="img-container">
                    <img src={lyrathol} alt="Example"/>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 order-1 order-lg-2 mt-lg-0">
                <div className="desc">
                  <h1>Lyrathol</h1>
                  <ul className="no-style inline tags">
                    <li>Bootstrap</li>
                    <li>jQuery</li>
                    <li>Sass</li>
                  </ul>
                  <a className="button-outline-dark-thin-small ml-0 mb-3 mt-2" href="/webdesign/lyrathol">View</a>
                  <p>Pharmaceuticals site. Based on the structure of existing pharmaceuticals websites. Typeface is easily readable; high contrast colors for CTA.</p>
                  <ul className="no-style inline tags">
                    <li>Pharmaceuticals</li>
                    <li>Advertisment</li>
                    <li>Public Health</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section p-small">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-lg-8 order-2 order-lg-1 mt-3 mt-lg-0">
                <div className="site-example">
                  <a className="hover-state" href="/webdesign/music">
                    <i className="fas fa-external-link-alt"></i>
                  </a>
                  <div className="img-container">
                    <img src={music} alt="Example"/>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 order-1 order-lg-2">
                <div className="desc">
                  <h1>Music Tour</h1>
                  <ul className="no-style inline tags">
                    <li>Bootstrap</li>
                    <li>jQuery</li>
                    <li>Sass</li>
                  </ul>
                  <a className="button-outline-dark-thin-small ml-0 mb-3 mt-2" href="/webdesign/music">View</a>
                  <p>A minimalist website advertising a music tour.</p>
                  <ul className="no-style inline tags">
                    <li>Entertainment</li>
                    <li>Advertisment</li>
                    <li>Music</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer/>
      </div>
    )
  }
}

export default WebConcepts;
