import React, {Component} from 'react';

import {backgroundImage} from "../../helpers/helpers";
import Footer from '../footer';
import Header from '../header';

import hero_bg from '../../sources/img/boxes/box_hero.jpg';

import targeting from '../../sources/img/boxes/box_targeting.svg';
import raytrace from '../../sources/img/boxes/box_raytrace.svg';
import personalBubble from '../../sources/img/boxes/box_personal_bubble.svg';
import grid from '../../sources/img/boxes/box_grid.svg';
import avoidance from '../../sources/img/boxes/box_avoidance.svg';
import part_1 from '../../sources/img/boxes/box_part_1.svg';

import video_blink from '../../sources/img/boxes/cube_blink.webm';
import video_gaze from '../../sources/img/boxes/cube_gaze.webm';
import video_pupil from '../../sources/img/boxes/cube_pupil.webm';

import video_blink_mp4 from '../../sources/img/boxes/cube_blink.mp4';
import video_gaze_mp4 from '../../sources/img/boxes/cube_gaze.mp4';
import video_pupil_mp4 from '../../sources/img/boxes/cube_pupil.mp4';

import bg_02 from '../../sources/img/sound-mixer/bg_02.jpg';

import cube_eyes from '../../sources/img/boxes/Cube_Eyes.png';
class Boxes extends Component{
  render(){
    const Hero_BG = backgroundImage(hero_bg);
    const BG_02 = backgroundImage(bg_02);
    return(
      <div className="boxes">
        <Header sticky back/>
        <section className="hero-60 section dark sound-mixer-hero">
          <i className="background opacity-6" style={Hero_BG}/>
          <div className="container v-center">
            <div className="v-center-child">
              <h1>Hoppin' Boxes</h1>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12 col-md-3 col-lg-2 col-xl-2 d-none d-md-block">
                <div className="built-with">
                  <h4>Built With</h4>
                  <ul>
                    <li><p>Unreal Engine</p></li>
                    <li><p>C++</p></li>
                    <li><p>Maya</p></li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-7">
                <div className="row justify-content-center">
                  <div className="col-10 col-xl-6">
                    <h2>An Experiment In AI & Personality</h2>
                    <p>I use Unreal Engine to simulate large amounts of AI actors that respond to their surrounding. Each actor is a simple geometric cube with heavily detailed eyes.</p>
                    <p>The goal is to create a sense of personality in these otherwise primitive shapes. Each individual actor (Cube) uses a series of collision detection and prediction methods to navigate around their environment and each other.</p>
                  </div>
                  <div className="col-10 col-xl-6">
                    <h2>Physics Driven</h2>
                    <p>The Cubes operate with the physics system of Unreal Engine, using applied forces to hop forward. The angle, intensity, and torque applied is determined based on the cube's knowledge of its environment.</p>
                    <p>This is to say, the Cube actors must navigate the world differently than a traditional AI actor. Cube's must be able to identify potential collisions, and be equipped to recognize and navigate out of "Stuck" scenarios.</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-7 pt-5">
                <div className="video-wrapper">
                  <iframe src="https://www.youtube-nocookie.com/embed/1GpHgQVNjwE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className="youtube-video"></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-11 col-lg-5">
                <h2>Targeting and Movement</h2>
                <p>A cube will generally have a <span className="box-main-target">Main Target</span> that can be arbitrarily defined (i.e. A position vector in world coords, a dynamic actor, etc.).</p>
                <p>Using a modified implementation of the Unreal Engine AI solution, the cube defines a series of nodes through navigable space that lead to the <span className="box-main-target">Main Target</span>. This provides a series of <span className="box-path-target">Pathing Targets</span>.</p>
                <p>The cube than has a <span className="box-local-target">Local Target</span> derived from the <span className="box-path-target">Pathing Target</span>. This <span className="box-local-target">Local Target</span> is what the cube moves towards.</p>
                <p>While the <span className="box-main-target">Main Target</span> and the <span className="box-path-target">Pathing Targets</span> are generally static, the <span className="box-local-target">Local Target</span> can be effected by dynamic elements around it.</p>
              </div>
              <div className="col-md-7 col-lg-6 col-xl-5">
                <div className="v-center">
                  <div className="v-center-child">
                    <img src={targeting} className="box-img" alt="Box AI Targeting Example"/>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-7 pt-5">
                <div className="video-wrapper">
                  <iframe src="https://www.youtube-nocookie.com/embed/QHueprrWPuc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className="youtube-video"></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-11 col-lg-5">
                <div className="v-center">
                  <div className="v-center-child">
                    <h2>Grid Based Collision Avoidance</h2>
                    <p>The AI driven cubes operate within a defined volume split into an arbitrarily sized grid. Cubes are sorted into a 2D array that designates their position within that grid. Cubes will only run collision detection and avoidance against other cubes in the same cells they inhabit.</p>
                    <p>Each cell overlaps with their neighbors, any cube within these overlaps is sorted into every cell it overlaps with.</p>
                    <p>The frequency of position updates can be tweaked for optimization. Further optimization can be made by running less or simplified positional updates on cells further away from the camera.</p>
                    <p>This system allows the amount of operating AI Cubes to be pushed into the 1000s, despite the intended number of AI Cubes being significantly lower.</p>
                  </div>
                </div>
              </div>
              <div className="d-none d-lg-block col-1"/>
              <div className="col-8 col-lg-5 col-xl-4">
                <div className="v-center">
                  <div className="v-center-child">
                    <img src={grid} className="box-img" alt="Box Grid Sorting Example"/>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-7 pt-5">
                <div className="video-wrapper">
                  <iframe src="https://www.youtube-nocookie.com/embed/Sqq0gvSzSC4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className="youtube-video"></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <img src={part_1} alt="Part 1" className="boxes-part-1"/>
          <div className="container-fluid">
            <div className="row justify-content-center">
            <div className="col-4 col-sm-3 col-md-2 col-xl-1 d-block d-xl-none"></div>
              <div className="col-7 col-md-6 col-lg- 8 col-xl-5">
                <h1 className="">Collision Checks</h1>
                <p>Each cube actor makes a series of checks before commiting to a hop. These checks effect the hop-force vector, the torque, and the position of the Local Target.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="section p-small">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-11 col-lg-7 col-xl-5">
                <h3>#1: Forward Raytrace</h3>
                <p>A raytrace is projected out of the lower center of the cube, forward 1.5x the cube's length. This is repeated two more times for the lower left and lower right sides of the cube's face.</p>
                <p>The results of these checks modify the force vector and torque applied to the cube when it hops to assist in avoidance.</p>
                <p>This method is used for navigating stationary or untracked obstacles. The AI pathing system avoids most of these types of collisions, while these checks pick up on any detail that may have been missed by the navigation mesh.</p>
              </div>
              <div className="d-none d-xl-block col-1"/>
              <div className="col-8 col-lg-7 col-xl-4">
                <div className="v-center">
                  <div className="v-center-child">
                    <img src={raytrace} className="box-img" alt="Box AI Collision Raytracing Example"/>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-7 pt-5">
                <div className="video-wrapper">
                  <iframe src="https://www.youtube-nocookie.com/embed/H98ztkBzS0Y" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className="youtube-video"></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section p-small">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-11 col-lg-5">
                <div className="v-center">
                  <div className="v-center-child">
                    <h3>#2: Personal Bubble</h3>
                    <p>Each cube has a "Personal Bubble" surrounding it. Cubes will do their best to keep their Personal Bubble clear of any other actor.</p>
                    <p>When a cube's personal bubble is invaded, it will attempt to move away from the invader, the force of the maneuver dependent on how far inside the bubble the invader is.</p>
                  </div>
                </div>
              </div>
              <div className="d-none d-lg-block col-1"/>
              <div className="col-8 col-lg-5 col-xl-4">
                <div className="v-center">
                  <div className="v-center-child">
                    <img src={personalBubble} className="box-img" alt="Box AI Personal Bubble Example"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section p-small">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-11 col-lg-5">s
                <div className="v-center">
                  <div className="v-center-child">
                    <h3>#3: Collision Avoidance</h3>
                    <p>Cubes want to avoid bumping headfirst into each other, so their local target is compared against the positions of the other cubes in the cell.</p>
                    <p>The Local Target is than offset away from the closest opponent cubes.</p>
                    <br/>
                    <br/>
                    <h3>#4: Predictive Avoidance</h3>
                    <p>Cubes will also predict the next position of any opponent cube in front of them.</p>
                    <p>Avoidance is calculated in much the same way as the cube actors</p>
                  </div>
                </div>
              </div>
              <div className="d-none d-lg-block col-1"/>
                <div className="col-8 col-lg-5 col-xl-4">
                  <div className="v-center">
                    <div className="v-center-child">
                        <img src={avoidance} className="box-img" alt="Box AI Avoidance Example"/>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </section>
        <section className="section dark box-videos">
          <i className="background hard-light opacity-1 top" style={BG_02}></i>
          <div className="container-fluid">
            <div className="row justify-content-center align-items-center">
              <div className="col-12 col-xl-4 mb-5 mb-xl-0">
                <h3>Functions of the Eyes</h3>
                <p>Each eye posses 3 relatively simple capabilities:</p>
                <ul>
                  <li>Eyelids animate between the state of opened and closed (Any any state in between).</li>
                  <li>Eyeballs can look directly at an arbitrarily defined point or actor.</li>
                  <li>Pupils can smoothly expand and contract.</li>
                  <li>These capabilities can be called and changed arbitrarily at runtime.</li>
                </ul>
                <p>Combined, these 3 capabilities can be used to simulate a range of emotion and expression.</p>
              </div>
              <div className="col-6 col-md-4 col-xl-2 d-flex justify-content-center mb-5 mb-md-0">
                <div>
                  <p>Blinking Eyelids</p>
                  <video autoPlay loop className="box-video">
                    <source src={video_blink} type="video/webm"/>
                    <source src={video_blink_mp4} type="video/mp4"/>
                  </video>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2 d-flex justify-content-center mb-5 mb-md-0">
                <div>
                  <p>Shifting Gaze</p>
                  <video autoPlay loop className="box-video">
                    <source src={video_gaze} type="video/webm"/>
                    <source src={video_gaze_mp4} type="video/mp4"/>
                  </video>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2 d-flex justify-content-center mb-5 mb-md-0">
                <div>
                  <p>Contracting Pupils</p>
                  <video autoPlay loop className="box-video">
                    <source src={video_pupil} type="video/webm"/>
                    <source src={video_pupil_mp4} type="video/mp4"/>
                  </video>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer/>
      </div>
    )
  }
}

export default Boxes;
