import React, {Component} from 'react';
import Header from '../header';
import {Link} from 'react-router-dom';
import {backgroundImage} from "../../helpers/helpers";
import hero_bg from '../../sources/img/backgrounds/linedpaper.png';

class AboutMe extends Component {
  render(){
    const Hero_BG = backgroundImage(hero_bg);
    return(
      <div className="aboutMe">
        <Header transparent aboutMe back/>
        <section className="hero-100 header-padding">
          <i className="background overlay opacity-10 repeat" style={Hero_BG}/>
          <div className="container v-center">
            <div className="v-center-child">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="about-me-info text-smaller">
                    <ul className="no-style social inline">
                      <li><a href="#"><i className="fab fa-linkedin"></i></a></li>
                      <li><a href="mailto:dougwatro@gmail.com"><i className="fas fa-envelope-square"></i></a></li>
                    </ul>
                    <h1>Hey! I'm Doug</h1>
                    <p>Really happy you decided to check out my portfolio!</p>
                    <p>I'm always working on something, right now I'm putting a lot of my attention towards my <Link to="/sound_mixer" className="link">Sound Mixer Web-App</Link></p>
                    <p>I know many of the heavy hitting languages, and I'm always interested in learning more. While I focus on web development, I can also work in a game design or software development environment.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default AboutMe;
