import React, {Component} from 'react';

import Footer from '../footer';
import Header from '../header';

import example_01 from '../../sources/img/indecision/example_01.mp4';
import example_02 from '../../sources/img/indecision/example_02.mp4';
import example_03 from '../../sources/img/indecision/example_03.mp4';

import subtle_01 from '../../sources/img/indecision/subtle_01.mp4';
import subtle_02 from '../../sources/img/indecision/subtle_02.mp4';
import subtle_03 from '../../sources/img/indecision/subtle_03.mp4';

import textLogo from '../../sources/img/indecision/text_logo.png';
import screenshot1 from '../../sources/img/indecision/screenshot1.png'
import screenshot2 from '../../sources/img/indecision/screenshot2.png'

import fast from '../../sources/img/indecision/Fast.svg';
import simple from '../../sources/img/indecision/Simple.svg';
import reusable from '../../sources/img/indecision/Reusable.svg';

class Indecision extends Component{
  render(){
    return(
      <div className="indecision">
        <Header sticky back/>
          <section className="hero-60 section bg-indecision">
            <div className="container h-100">
              <div className="row justify-content-center align-items-center h-100">
                <div className="col-12 d-flex justify-content-center">
                  <img src={textLogo} alt="Indecison"/>
                </div>
              </div>
            </div>
          </section>
          <section className="section">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2 d-none d-sm-block">
                  <div className="built-with pl-xl-5 pl-lg-2">
                    <h4>Built With</h4>
                    <ul>
                      <li><p>Android SDK</p></li>
                      <li><p>Java</p></li>
                    </ul>
                  </div>
                </div>
                  <div className="col-12 col-sm-8 col-md-9 col-lg-4 col-xl-4">
                    <h2>The Theory</h2>
                    <p></p>
                    <p>The goal is to create a sense of personality in these otherwise primitive shapes. Each individual actor (Cube) uses a series of collision detection and prediction methods to navigate around their environment and each other.</p>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 pt-4 pt-lg-0 screenshots">
                    <img src={screenshot1} alt="Example"/>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xl-3 pt-4 pt-lg-0  screenshots">
                    <img src={screenshot2} alt="Example"/>
                  </div>
              </div>
            </div>
          </section>
          <section className="section dark p-small">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-12 col-sm-10 col-md-9 col-lg-3 col-lg-3">
                  <div className="pl-xl-5">
                    <h2>How It Works</h2>
                    <p>The user will have a moment of indecision, where perhaps they may be unable to decide which movie to see, or at which restaurant to eat.</p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-lg-3 text-center mt-5 mt-lg-0">
                  <p className="video-desc">Recognizing this, the user would create a group in InDecision...</p>
                  <video autoPlay loop className="indecision-video">
                    <source src={example_01} type="video/mp4"/>
                  </video>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-lg-3 text-center mt-5 mt-lg-0">
                  <p className="video-desc">...and fill it with all their potential options.</p>
                  <video autoPlay loop className="indecision-video">
                    <source src={example_02} type="video/mp4"/>
                  </video>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-lg-3 text-center mt-5 mt-lg-0">
                  <p className="video-desc">Then, at the tap of a button, the user can receive a random suggestion.</p>
                  <video autoPlay loop className="indecision-video">
                    <source src={example_03} type="video/mp4"/>
                  </video>
                </div>
              </div>
            </div>
          </section>
          <section className="section p-small">
            <div className="container-fluid">
              <div className="row creeds justify-content-around">
                <div className="col-12 col-lg-3 core">
                  <h3>Core Creeds</h3>
                  <p>I find it important to set core philosophies when design and programming.</p>
                  <p>Especially with such a simple application, it's easy to fall down the rabbit hole of adding feature after feature.</p>
                  <p>A set of core design goals helps focus be kept. These were three of the main guidelines I followed.</p>
                </div>
                <div className="col-12 col-lg-3 col-xl-2">
                  <img src={simple} alt=""/>
                  <h4>Simple</h4>
                  <p>Users should be able to spontaneously create new groupings quickly and with minimal interaction.</p>
                </div>
                <div className="col-12 col-lg-3 col-xl-2">
                  <img src={fast} alt=""/>
                  <h4>Fast</h4>
                  <p>Users should be able get a result instantly. Results should be obtainable without leaving the front page.</p>
                </div>
                <div className="col-12 col-lg-3 col-xl-2">
                  <img src={reusable} alt=""/>
                  <h4>Reusable</h4>
                  <p>Users should ideally use this app in their day to day moments of indecision. The previous two points influence this one.</p>
                </div>
              </div>
            </div>
          </section>
          <section className="section dark p-small">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-12 col-sm-10 col-md-9 col-lg-3 col-lg-3">
                  <div className="pl-xl-5">
                    <h2>Subtlety</h2>
                    <p>Even the smallest interactions have been given subtle but satisfying responses. I've used apps before that lacked these small details, and while they were perfectly functional, the experience felt rigid. I aimed to prevent that wherever possible, without sacrificing InDecision's core of simplicity.</p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-lg-3 text-center mt-5 mt-lg-0">
                  <video autoPlay loop className="indecision-video">
                    <source src={subtle_01} type="video/mp4"/>
                  </video>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-lg-3 text-center mt-5 mt-lg-0">
                  <video autoPlay loop className="indecision-video">
                    <source src={subtle_02} type="video/mp4"/>
                  </video>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-lg-3 text-center mt-5 mt-lg-0">
                  <video autoPlay loop className="indecision-video">
                    <source src={subtle_03} type="video/mp4"/>
                  </video>
                </div>
              </div>
            </div>
          </section>
        <Footer/>
      </div>
    );
  }
}

export default Indecision;
