import React, {Component} from 'react';

class Footer extends Component {
  render(){
    return(
      <section className="section p-xsmall dark footer">
        <div className="container">
          <ul className="social no-style inline">
            <li><a href="#"><i className="fab fa-linkedin"></i></a></li>
            <li><a href="mailto:dougwatro@gmail.com"><i className="fas fa-envelope-square"></i></a></li>
          </ul>
          <a href="#top">Back To Top</a>
          <p className="copyright">Copyright © 2019 Douglas Watro All Rights Reserved</p>
        </div>
      </section>
    )
  }
}

export default Footer;
